import {Injectable} from '@angular/core';
import {Subject} from "rxjs";
import {environment} from "../../environments/environment";
import {AuthService} from "@makeo-packages/mkongusers";

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  private ws: WebSocket;
  public onMessage = new Subject();
  constructor(private authService: AuthService) {
    this.openWS();
  }

  private openWS() {
    if ((!this.ws || this.ws.readyState != this.ws.OPEN) && this.authService.accessToken) {
      this.ws = new WebSocket(environment.wsAdress + '?access_token=' + this.authService.accessToken)
      this.ws.onmessage = (ev) => {
        if (ev.data[0] == '{') {
          const dataWS = JSON.parse(ev.data);
          this.onMessage.next(dataWS);
        }
        else {
          console.error("Bad formt WS Message received : " + ev.data)
        }
      }

      this.ws.onclose = (ev) => {
        if (ev.code !== 1000 && ev.reason !== 'refresh_token' && ev.reason !== 'leave') {
          setTimeout(() => this.openWS(), 3000)
        }
      }
    }
  }

  changeTokenWS() {
    if (this.ws?.readyState == this.ws?.OPEN) {
      this.ws?.close(1000, 'refresh_token');
    }
    this.openWS();
  }
}
